import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "attenuation"
    }}>{`Attenuation`}</h1>
    <p>{`Attenuation is a measure of how complete the yeast fermented at wort,
and it is usually expressed in a percentage.`}</p>
    <h2 {...{
      "id": "calculation"
    }}>{`Calculation`}</h2>
    <pre><code parentName="pre" {...{}}>{`[(FG - OG) / ( OG - 1 )] * 100
`}</code></pre>
    <ul>
      <li parentName="ul">{`OG: Original Gravity`}</li>
      <li parentName="ul">{`FG: Final Gravity`}</li>
    </ul>
    <h2 {...{
      "id": "in-practice"
    }}>{`In Practice`}</h2>
    <p>{`should daily check the gravity. Checking attenuation is a simple step to make consistent, high-quality
beer.`}</p>
    <p>{`Normally from 65 to 85 percent. A real attenuation of 100 percent is rare, because wort contains a complex mixture of carbohydrates,
with many of them being unfermentable.`}</p>
    <p>{`Wort contains five fermentable sugars: glucose, fructose, sucrose, maltose and maltotriose.
Maltose > maltotriose > glucose
Yeast cannot ferment dextrins, and yeast strains differ in their ability to ferment maltotriose.`}</p>
    <p>{`A well fermented beer have complex carbohydrates result in a higher finishing gravity, and they
do not taste sweet. But other factor like presence of various alcohols and other flavor compounds.`}</p>
    <p>{`Generally, a higher start gravity beer result to a higher end gravity beer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      